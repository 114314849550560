<template>
  <router-view v-slot="{ Component}">
    <keep-alive :exclude="['signUp']">
        <component :is="Component"/>
    </keep-alive>
</router-view>
</template>

<script>
// import signUp from './signUp';
export default {
  name: 'App',
  components: {
  }
}
</script>
